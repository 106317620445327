import axios from "../../../utils/api"

export const SET_LOCKERS_LIST = 'SET_LOCKERS_LIST'
export const SET_LOCATION_NAME = 'SET_LOCATION_NAME'
export const SET_OCCUPATION = 'SET_OCCUPATION'

export const getLockers = (locationId, token) => (dispatch, getState) =>
new Promise(function(resolve, reject){
  console.log('getLocker')
  axios.get('/api/julian/lockersByPlace/'+locationId, {
    headers: {
        "Authorization": `Bearer ${token}`
      }
    })
  .then(function (response) {
    console.log(response)
    dispatch({
      type: "SET_LOCKERS_LIST",
      lockersList: response.data.Data.Lockers
    })
    resolve()
  })
  .catch(function (error) {
    console.log(error)
  })
})

export const getOccupation = (token) => (dispatch, getState) =>
new Promise(function(resolve, reject){
  console.log('getOccupation')
  axios.get('/api/julian/dashboard/getPlacesOccupationRate', {
    headers: {
        "Authorization": `Bearer ${token}`
      }
    })
  .then(function (response) {
    console.log(response)
    dispatch({
      type: "SET_OCCUPATION",
      payload: response.data.Data[0]
    })
    resolve()
  })
  .catch(function (error) {
    console.log(error)
  })
})

export const getPlaceDetails = (locationId, token) => (dispatch, getState) =>
new Promise(function(resolve, reject){
  console.log('getPlaceDetails')
  axios.get('/api/julian/PlaceDetails/'+locationId, {
    headers: {
        "Authorization": `Bearer ${token}`
      }
    })
  .then(function (response) {
    console.log(response)
    dispatch({
      type: "SET_LOCATION_NAME",
      locationName: response.data.Data.Place.name
    })
    resolve()
  })
  .catch(function (error) {
    console.log(error)
  })
})
