import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"
import CountdownTimer from './../../../components/CountdownTimer'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ReactComponent as WarningLocker } from './../../../assets/images/WarningLocker.svg'



class ErrorWrongLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: null,
      isShowingModal: false,
      waitTime: 5,

    }
  }
  toggle = () => {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    this.setState({
      isShowingModal: true,
    })
    this.timeout = setTimeout(
      function () {
        this.setState({
          isShowingModal: false,
        })
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      5000
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <Modal external={<CountdownTimer time={this.state.waitTime} />} isOpen={this.state.isShowingModal} toggle={this.toggle} className="wrong-location-error-modal">
        <ModalHeader>
          ATENÇÃO!<br />
          O seu cacifo está em {this.props.location_name}.
        </ModalHeader>
        <ModalBody>
          <WarningLocker />
          <p className="wrong-location-locker-number">{this.props.locker ? this.props.locker : '16'}</p>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    locker: state.requestLocker.locker.locker_number,
    location_name: state.requestLocker.locker.locker_location_name
  }
}

export default connect(mapStateToProps)(ErrorWrongLocation)
