import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"
import CountdownTimer from './../../../components/CountdownTimer'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ReactComponent as SuccessIcon } from './../../../assets/images/SucessIcon.svg'



class ReturnLockerConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmModal: false,
      waitTime: 3,

    }
  }

  componentDidMount() {
    this.toggleConfirmModal()
    this.timeout = setTimeout(
      function () {
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      3000
    )
  }

  toggleConfirmModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal })
  }

  componentWillUnmount() {
    this.toggleConfirmModal()
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <Modal external={<CountdownTimer time={this.state.waitTime} />} isOpen={this.state.showConfirmModal} toggle={this.toggleConfirmModal} className="confirm-return-locker-modal">
        <ModalBody>
          <div className="wrapper">
            <SuccessIcon />
            <div className="text-div">
              <p>
                Cacifo foi libertado com sucesso!<br />
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    location: state.location,
    locker: state.locker
  }
}

export default connect(mapStateToProps)(ReturnLockerConfirmation)
