import React from "react"
import {
  Row,
  Col,
  Image,
  Modal,
  Button
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import IconButton from '../components/IconButton'
import OccupationBar from '../components/OccupationBar'
import MyButton from '../components/MyButton'
import { ReactComponent as HelpButton } from './../assets/images/help_icon.svg'
import { getOccupation } from '../redux/actions/occupation'
import axios from 'axios'
import HelpModal from './../views/pages/help/index'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      time: null,
      repeatRequest: null,
      occupationPercent: 0,
      canRefresh: true,
      canRefreshTimeout: null,
      showRequestHelp: false,
      isRequestingAssistance: false,
      showRequestHelpTimeout: null,
      requestHelpNotification: '',
      enableAssistance: '0',
      enableXmas: '0',
      enableHalloween: '0',
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)

    const enableXmas = urlParams.get('EnableXmas')
    this.setState({ enableXmas: enableXmas })

    const enableHalloween = urlParams.get('EnableHalloween')
    this.setState({ enableHalloween: enableHalloween })

    const enableAssistance = urlParams.get('EnableAssistance')
    this.setState({ enableAssistance: enableAssistance })

    const locationId = urlParams.get('Location')
    const token = urlParams.get('Token')
    var self = this
    self.props.dispatch(getOccupation(token))
    var repeatRequest = setInterval(function () {
      self.props.dispatch(getOccupation(token))
    }, 60000)
    this.setState({ repeatRequest: repeatRequest })

  }

  componentWillUnmount() {
    clearInterval(this.state.repeatRequest)
    clearTimeout(this.state.canRefreshTimeout)
    clearTimeout(this.state.showRequestHelpTimeout)
  }

  refreshOccupation = () => {
    if (this.state.canRefresh === true) {
      this.setRefreshCooldown()
      const urlParams = new URLSearchParams(window.location.search)
      const locationId = urlParams.get('Location')
      const token = urlParams.get('Token')
      this.props.dispatch(getOccupation(token))
    }
  }

  setRefreshCooldown = () => {
    this.setState({ canRefresh: false })
    var self = this
    var canRefreshTimeout = setTimeout(function () {
      self.setState({ canRefresh: true })
    }, 10000)

    this.setState({ canRefreshTimeout: canRefreshTimeout })
  }

  /*calcOccupation = () => {
    var totalLockers = this.props.lockerList.length
    var occupiedLockers = 0
    this.props.lockerList.forEach((item, i) => {
      if(item.status != 0 || item.locker_metra_status != 2){
        occupiedLockers++
      }
    })
    var occupationPercent = Math.round(((occupiedLockers/totalLockers) * 100))
    this.setState({occupationPercent: occupationPercent})
  }*/

  goHome = () => {
    this.props.history.push('/' + window.location.search)
  }

  callAssistance = () => {
    var self = this
    if (!this.state.isRequestingAssistance) {
      clearTimeout(this.state.showRequestHelpTimeout)
      this.setState({ isRequestingAssistance: true })
      axios.post(`http://qa.purpleprofile.pt:5060/call-assistance`, {
        location: this.props.locationName
      })
        .then(res => {
          console.log(res)
          self.notify('O pedido de assistência foi efetuado. Por favor aguarde.')
          self.setState({ isRequestingAssistance: false })
        })
        .catch(error => {
          console.log(error)
          self.notify('Não foi possível pedir assistência.')
          self.setState({ isRequestingAssistance: false })
        })
    }
  }

  notify = (msg) => {
    this.setState({ requestHelpNotification: msg })

    var self = this
    var showRequestHelpTimeout = setTimeout(function () {
      self.setState({
        showRequestHelp: false,
        requestHelpNotification: ''
      })
    }, 6000)

    this.setState({ showRequestHelpTimeout: showRequestHelpTimeout })
  }

  closeModal = () => {
    if (!this.state.isRequestingAssistance) {
      clearTimeout(this.state.showRequestHelpTimeout)
      this.setState({ showRequestHelp: false })
    }
  }

  showModal = () => {
    this.setState({ showRequestHelp: true })
    var self = this
    var showRequestHelpTimeout = setTimeout(function () {
      self.setState({ showRequestHelp: false })
    }, 6000)

    this.setState({ showRequestHelpTimeout: showRequestHelpTimeout })
  }


  toggleHelpModal = () => {
    if (this.state.showRequestHelp == false) {
      this.setState({ showRequestHelp: true })
      this.timeout = setTimeout(
        function () {
          this.setState({
            showRequestHelp: false,
          })
        }
          .bind(this),
        10000000
      )
    }
    else {
      this.setState({ showRequestHelp: !this.state.showRequestHelp })
    }
  }



  render() {
    const helpModal = (
      <HelpModal
        isOpen={this.state.showRequestHelp}
        toggle={this.toggleHelpModal}
        className="help-modal"
        time={10}
      />
    )

    const defaultFooter = (
      <>
        <div className="default-footer-container">
          {this.state.enableAssistance === '1' &&
            <div className="request-help-button" onClick={() => this.showModal()}>
              <Row className="justify-content-center">
                <Image
                  src="images/request-assistance.svg"
                  className="call-assistance-icon" />
                <p className="call-assistance-text">Pedir assistência</p>
              </Row>
            </div>
          }
          {helpModal}
          <Row className="main-row">
            <div className="lotation-container">
              <Row className="m-0">
                Lotação<span>
                  {this.props.occupation && this.props.locationName &&
                    this.props.occupation[this.props.locationName]}%</span>
              </Row>
              <Row className="m-0">
                {this.props.occupation &&
                  <OccupationBar history={this.props.history} refreshOccupation={this.refreshOccupation} occupation={this.props.occupation[this.props.locationName]} />
                }
              </Row>
            </div>
            <div className="button-container">
              <IconButton icon={HelpButton} onClick={() => this.toggleHelpModal()} />
            </div>
          </Row>
        </div>
      </>
    )

    const requestHelpFooter = (
      <div className={`footer-request-help-container ${this.state.showRequestHelp ? 'appear' : 'disappear'}`}>
        {this.state.requestHelpNotification === '' &&
          <>
            <Row className="align-items-center justify-content-center footer-text">
              <p>Pretende chamar alguém para lhe prestar assistência?</p>
            </Row>
            <Row className="align-items-center justify-content-center footer-request-help-buttons">
              <Col lg="6">
                <Row className="justify-content-end">
                  <MyButton value="Cancelar" color="danger" onClick={this.closeModal} />
                </Row>
              </Col>
              <Col lg="6">
                <MyButton value="Pedir assistência" color="primary" onClick={this.callAssistance} />
              </Col>
            </Row>
          </>
        }
        {this.state.requestHelpNotification !== '' &&
          <Row className="align-items-center justify-content-center footer-text request-help-notification">
            <p>{this.state.requestHelpNotification}</p>
          </Row>
        }
      </div>
    )

    return (
      <div className={`footer-container ${this.state.showRequestHelp ? "footer-container-expanded" : "footer-container-compacted"}`}>
        {this.state.enableXmas === '1' &&
          <>
            <Image src="images/snowman.png" className="xmas-snowman" />
            <Image src="images/xmas-tree.png" className="xmas-tree1" />
            <Image src="images/xmas-tree.png" className="xmas-tree2" />
            <Image src="images/xmas-tree.png" className="xmas-tree3" />
          </>
        }
        {this.state.enableHalloween === '1' && <Image src="images/ghost.png" className="halloween-ghost" />}
        {/* {this.state.showRequestHelp ? requestHelpFooter :*/ defaultFooter}
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    lockerList: state.occupation.lockersList,
    locationName: state.occupation.locationName,
    occupation: state.occupation.occupation
  }
}

export default connect(mapStateToProps)(withRouter(Footer))
