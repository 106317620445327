import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"
import { Modal, ModalBody } from 'reactstrap'
import CountdownTimer from './../../../components/CountdownTimer'
import { ReactComponent as ErrorIcon } from './../../../assets/images/ErrorRedIcon.svg'




class ErrorNoIds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: null,
      isShowingModal: false,
      waitTime: 3,
    }
  }

  toggle = () => {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    this.setState({
      isShowingModal: true,
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }


  render() {
    return (
      <Modal external={<CountdownTimer />} isOpen={this.state.isShowingModal} toggle={this.toggle} className="no-ids-error-modal">
        <ModalBody>
          <div className="wrapper">
            <ErrorIcon />
            <div className="text-div">
              <p>
                Terminal não configurado!
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(ErrorNoIds)
