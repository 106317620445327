import React from "react"
import axios from '../../../utils/api'
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import { requestLocker, setLocker } from '../../../redux/actions/requestLocker'
import { setMode } from '../../../redux/actions/mode'
import MyButton from '../../../components/MyButton'
import { connect } from "react-redux"
import CardAnimation from './../../../assets/images/CardPurple.png'

class IdleScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasReadCard: false,
      enableNotice: '0',
    }
  }

  componentDidMount() {
    this.props.dispatch(setMode('requestLocker'))
    const urlParams = new URLSearchParams(window.location.search)

    const enableNotice = urlParams.get('EnableNotice')
    this.setState({ enableNotice: enableNotice })

    const readerId = urlParams.get('Id')
    const location = urlParams.get('Location')
    const token = urlParams.get('Token')
    if (token == null || location == null || readerId == null) {
      this.props.history.push('/error-no-ids' + window.location.search)
    }
  }

  render() {
    return (
      <Row className="background">
        <Row className="info">
          <div className="wrapper">
            <img src={CardAnimation} />
          </div>
          <span>Passe o seu cartão no leitor para obter<br />
            ou libertar um cacifo.</span>
        </Row>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(IdleScreen)
