import React from "react"

class ProgressCircle extends React.Component{
  render(){
    const { radius,
      percentage,
      height,
      width,
      strokeWidth,
      showText,
      textSize} = this.props
    const cx = width / 2
    const cy = height / 2
    const circumference = 2 * Math.PI * radius
    const progress = ((100 - percentage) / 100) * circumference
    return (
      <svg height={height} width={width}>
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
           <stop offset="0%" stopColor="#0ed392"></stop>
           <stop offset="100%" stopColor="#0CB1DE"></stop>
        </linearGradient>
        {showText && <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="url(#linearColors)"
                        fontSize={textSize}
                        >
                        {percentage}%
                      </text>}
        <circle
          r={radius}
          cx={cx}
          cy={cy}
          fill="transparent"
          stroke="url(#linearColors)"
          strokeWidth={strokeWidth}
          opacity="0.2" />
        <circle
          r={radius}
          cx={cx}
          cy={cy}
          fill="transparent"
          stroke="url(#linearColors)"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          strokeLinecap="round" />
      </svg>
    )
  }
}

export default ProgressCircle
