import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"
import { Modal, ModalBody } from 'reactstrap'
import CountdownTimer from './../../../components/CountdownTimer'
import { ReactComponent as ErrorIcon } from './../../../assets/images/ErrorRedIcon.svg'



class ErrorAssigningLocker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: null,
      isShowingModal: false,
      waitTime: 3,
    }
  }
  toggle = () => {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    this.setState({
      isShowingModal: true,
    })
    this.timeout = setTimeout(
      function () {
        this.setState({
          isShowingModal: false,
        })
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      3000
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <Modal external={<CountdownTimer time={this.state.waitTime} />} isOpen={this.state.isShowingModal} toggle={this.toggle} className="releasing-error-modal">
        <ModalBody>
          <div className="wrapper">
            <ErrorIcon />
            <div className="text-div">
              <p>
                Ocorreu um erro ao libertar o cacifo!
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    location: state.location,
    locker: state.locker
  }
}

export default connect(mapStateToProps)(ErrorAssigningLocker)
