import React from "react"
import axios from '../../utils/api'
import {
  Row,
  Col,
  Image
} from "react-bootstrap"
import ActionButton from '../../components/ActionButton'
import CountdownTimer from './../../components/CountdownTimer'
import MyButton from '../../components/MyButton'
import { connect } from "react-redux"
import { requestLocker } from '../../redux/actions/requestLocker'
import { setMode } from '../../redux/actions/mode'
import moment from 'moment'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ReactComponent as SuccessLocker } from './../../assets/images/SuccessLocker.svg'
import { ReactComponent as ActiveLocker } from './../../assets/images/ActiveLocker.svg'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRequesting: false,
      showConfirmModal: false,
      waitTime: 5,
    }
  }

  componentDidMount() { 
   this.setTimer()
    this.toggleConfirmModal()
  }


  toggleConfirmModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal })
  }

  returnLocker = () => {
    if (this.state.isRequesting === false) {
      clearTimeout(this.state.timeout)
      //change redux state to mode: returnLocker then push to return locker location
      this.props.dispatch(setMode('returnLocker')).then(() => {
        this.props.history.push('/return-locker' + window.location.search)
      })
    } else {
      return null
    }
  }

  setTimer = () => {
    this.timeout = setTimeout(
      function () {
        this.props.history.push('/' + window.location.search)
      }
        .bind(this),
      5000
    )

  }

  componentWillUnmount() {
    this.toggleConfirmModal()
    clearTimeout(this.timeout)
  }

  render() {
    const { isRequesting } = this.state
    const { locker } = this.props


    return (
      <Modal external={<CountdownTimer time={this.state.waitTime} />} isOpen={this.state.showConfirmModal} toggle={this.toggleConfirmModal} className={this.props.status_duration > 120 ? "confirm-request-locker-modal-free" : "confirm-request-locker-modal"}>
        <ModalHeader><p> O seu cacifo é o {locker}</p></ModalHeader>
        <ModalBody>
        {this.props.status_duration > 120 ?
           <ActiveLocker /> : <SuccessLocker />
        }
          <p>{locker}</p>
        </ModalBody>
        {this.props.status_duration > 120 &&
        <button onClick={this.returnLocker}><span>Libertar Cacifo</span></button>}
        
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    locker: state.requestLocker.locker.locker_number,
    user: state.requestLocker.user,
    status_duration: state.requestLocker.locker.status_duration
  }
}

export default connect(mapStateToProps)(Home)
