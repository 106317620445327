import React, { ReactComponent } from "react"
import {
  Image
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'


class IconButton extends React.Component {
  render() {
    const { to, icon } = this.props

    const MyComponent = this.props.icon

    return (
      <div className="icon-button-wrapper" onClick={this.props.onClick}>
        <MyComponent
          className="icon-button-icon"
        />
      </div>
    )
  }
}

export default withRouter(IconButton)
