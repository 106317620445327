import React from "react"
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import { connect } from "react-redux"
import MyButton from '../../../components/MyButton'
import CountdownTimer from '../../../components/CountdownTimer'
import { setMode } from '../../../redux/actions/mode'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CardAnimation from './../../../assets/images/CardRed.png'


class ReturnLocker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: null,
      isShowingModal: false,
      waitTime: 5,
    }
  }

  toggle = () => {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
    this.goBack()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  goBack = () => {
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    this.setState({
      isShowingModal: true,
    })
    this.timeout = setTimeout(
      function () {
        //change redux state back to mode: requestLocker then go to idle screen
        this.setState({
          isShowingModal: false,
        })
        this.props.dispatch(setMode('requestLocker')).then(() => {
          this.props.history.push('/' + window.location.search)
        })
      }
        .bind(this),
      5000
    )
  }

  render() {
    const { locker } = this.props
    return (
      <Modal external={<CountdownTimer time={this.state.waitTime} />} isOpen={this.state.isShowingModal} toggle={this.toggle} className="assigning-locker-modal">
        <ModalBody>
          <ModalHeader> Deseja libertar o cacifo?</ModalHeader>
          <img src={CardAnimation} />
        </ModalBody>
        <span>Certifique-se que não deixa os seus valores.<br />
          Passe o cartão no leitor para libertar o seu cacifo.</span>
      </Modal>

    )
  }
}

const mapStateToProps = state => {
  return {
    location: state.requestLocker.location,
    locker: state.requestLocker.locker.locker_number,
  }
}

export default connect(mapStateToProps)(ReturnLocker)
