import React from "react"
import {
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap"
import { connect } from "react-redux"
import { setLocation } from '../../../redux/actions/requestLocker'

class Configuration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locationId: null
    }
  }

  handleSubmit = () => {
    this.props.dispatch(setLocation(this.state.locationId)).then(() => {
      console.log('success')
    }).catch(() => {
      console.log('error')
    })
  }

  handleIdInput = (value) => {
    this.setState({ locationId: value })
  }

  render() {
    return (
      <Row className="justify-content-center align-items-center">
        <Form>
          <Form.Group controlId="configuration">
            <Form.Label>ID Localização</Form.Label>
            <Form.Control type="number" placeholder="Insira o ID" onChange={(e) => this.handleIdInput(e.target.value)} />
          </Form.Group>
          <Row>
            <Col>
              <Button variant="primary" onClick={() => this.handleSubmit()}>
                Confirmar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    location: state.location,
  }
}

export default connect(mapStateToProps)(Configuration)
