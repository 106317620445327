import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import CountdownTimer from './../../../components/CountdownTimer'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
class Help extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <>
        <Modal external={<CountdownTimer time={this.props.time} />} isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className} centered={this.props.centered}>
          <ModalHeader>Precisa de Ajuda?</ModalHeader>
          <ModalBody>
            <Row className="modal-row">
              <Col className="col-6">
                <Row className="justify-content-start">
                  <p className="help-text-big">Para obter um cacifo:</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>1 -</span> Passe o cartão no leitor;</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>2 -</span> O número do cacifo que lhe foi atribuído é apresentado no ecrã;</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>3 -</span> Para abrir o seu cacifo, passe o cartão no leitor disponível na localização em causa.</p>
                </Row>
              </Col>
              <Col className="col-6">
                <Row className="justify-content-start">
                  <p className="help-text-big">Para libertar um cacifo:</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>1 -</span> Passe o cartão no leitor;</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>2 -</span> O número do seu cacifo é apresentado no ecrã;</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>3 -</span> Selecione - Libertar Cacifo;</p>
                </Row>
                <Row className="justify-content-start">
                  <p className="help-text"><span>4 -</span> Passe novamente o cartão no leitor para confirmar a ação.</p>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default Help
