import React from "react"
import { Progress } from 'reactstrap';

class OccupationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      occupation: 0,
      color: '#40CF9F'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.occupation != prevState.occupation) {
      if (nextProps.occupation > 95) {
        return {
          occupation: nextProps.occupation,
          color: '#C7715B'
        }
      } else if (nextProps.occupation > 75) {
        return {
          occupation: nextProps.occupation,
          color: '#f7902f'
        }
      } else if (nextProps.occupation <= 75) {
        return {
          occupation: nextProps.occupation,
          color: '#40CF9F'
        }
      }
    }
    return null
  }

  render() {
    const { color, occupation } = this.state
    return (
      <Progress value={occupation} />

    )
  }
}

export default OccupationBar
