import React from "react"
import axios from '../../../utils/api'
import {
  Row,
  Col,
  Image
} from "react-bootstrap"
import { connect } from "react-redux"
import { requestLocker } from '../../../redux/actions/requestLocker'
import CountdownTimer from './../../../components/CountdownTimer'
import MyButton from '../../../components/MyButton'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ReactComponent as SuccessLocker } from './../../../assets/images/SuccessLocker.svg'

class RequestLockerConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasLockerBeenSet: false,
      isRequesting: false,
      showConfirmModal: false,
    }
  }

  componentWillUnmount() {
    this.toggleConfirmModal()
    clearTimeout(this.timeout)
  }

  componentDidMount() {
    this.toggleConfirmModal()
    this.acceptLocker()
  }


  toggleConfirmModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal })
  }

  acceptLocker = () => {
    this.setState({ isRequesting: true })
    var self = this
    axios.defaults.headers.common = { 'Authorization': 'Bearer YWU2NjNmNTBhOTA1ODBhMTNhMTJkM2ZkYWE0MTBkZDZkYjY0MjhkZjliZjAzMDMyMDhjMzFjZDJiYmRjZjVmMA' }
    axios.post('/api/reader/?type=ASSIGN_LOCKER&id=131')
      .then(function (response) {
        console.log(response)
        self.setState({
          hasLockerBeenSet: true,
          isRequesting: false
        })
        this.timeout = setTimeout(
          function () {
            self.props.history.push('/' + window.location.search)
          }
            .bind(self),
          5000
        )
      })
      .catch(function (error) {
        console.log(error)
        self.setState({ isRequesting: false })
      })
  }

  render() {
    const { locker } = this.props
    const { hasLockerBeenSet, isRequesting } = this.state

    return (
      <Modal external={<CountdownTimer />} isOpen={this.state.showConfirmModal} toggle={this.toggleConfirmModal} className="confirm-request-locker-modal">
        <Row className="wrapper">
          <ModalHeader><p> O seu cacifo é o {locker}</p></ModalHeader>
          <ModalBody>
            <SuccessLocker />
            <p>{locker}</p>
          </ModalBody>
        </Row>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    location: state.requestLocker.location,
    locker: state.requestLocker.locker.locker_number
  }
}

export default connect(mapStateToProps)(RequestLockerConfirmation)
