import React from "react"
import moment from 'moment'
import {
  Row,
  Col,
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { getPlaceDetails } from '../redux/actions/occupation'
import {ReactComponent as LokkIcon} from './../assets/images/Lokk.svg'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      time: null
    };
  }

  tick() {
    this.setState(state => ({
      date: moment(Date.now()).format('DD-MM-YYYY'),
      time: moment(Date.now()).format('HH:mm:ss')
    }));
  }

  goHome = () => {
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const locationId = urlParams.get('Location')
    const token = urlParams.get('Token')
    this.props.dispatch(getPlaceDetails(locationId, token))
   this.interval = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var date = moment(Date.now()).format('DD-MM-YYYY')
    var time = moment(Date.now()).format('HH:mm')
    return (
        <Row className="container-fluid header-container">
          <Col className="col-4 first-col">
          <a href={process.env.REACT_APP_API_HOME}><LokkIcon />
          </a>
          </Col>
          <Col className="col-4 mid-col">
          <p>Lokk Shared</p>
          <span>{this.props.locationName && this.props.locationName}</span>
          </Col>
          <Col className="col-4 last-col">
            <div className="wrapper">
            <span>{ time }</span>
            <p>{ date }</p>
            </div>
          
          </Col>
        </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      locationName: state.occupation.locationName
  }
}

export default connect(mapStateToProps)(withRouter(Header))
