import React from "react"
import scssVars from "./../assets/sass/vars.scss"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { withRouter } from 'react-router-dom'

class CountdownTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time,
    }
  }

  render() {
    if (this.props.location.pathname === '/return-locker')
    return (
      this.props.time > 0 ?
        <div id="countdown-timer">
          <CountdownCircleTimer
            isPlaying
            duration={this.props.time}
            colors={[
              [scssVars.white]
            ]}
            ariaLabel={scssVars.transparent}
            size={120}
            trailColor={scssVars.transparent}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </div>
        : ''
    )
    else{
      return(
      this.props.time > 0 ?
        <div id="countdown-timer">
          <CountdownCircleTimer
            isPlaying
            duration={this.props.time}
            colors={[
              [scssVars.dark_blue]
            ]}
            ariaLabel={scssVars.transparent}
            size={120}
            trailColor={scssVars.transparent}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </div>
        : ''
      )
    }
  }
}

export default (withRouter(CountdownTimer)) 
