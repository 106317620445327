import React from "react"
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import { Modal, ModalBody } from 'reactstrap'
import CountdownTimer from './../../../components/CountdownTimer'
import ProcessingLoad from './../../../assets/images/Loading_Logo_Simple.webp'

class Processing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: null,
      isShowingModal: false,
    }
  }

  toggle = () => {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
    this.timeout = setTimeout(
      function () {
        this.setState({
          isShowingModal: false,
        })
      }
        .bind(this),
      2000
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <Modal isOpen={this.state.isShowingModal} toggle={this.toggle} className="processing-request-modal">
        <ModalBody>
          <div className="wrapper">
            <Image src={ProcessingLoad} className="process-loading" />
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default Processing
